import React, { Fragment } from "react"
import Slider from 'react-slick';
import styled from "styled-components"
import {Link} from 'gatsby'
//import { GatsbyImage } from 'gatsby-plugin-image'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'

const Otsikko = styled.h1`
padding: 0px;
margin: 0px;
font-weight: bold;
font-size: 16px;
`
const Teksti = styled.ul`
padding-top: 20px;
padding-bottom: 20px;
padding-left:20px;
padding-right:20px;
margin:0;
font-size: 14px;
line-height: 20px;
`
const Karuselli = styled(Slider)`
padding-top: 14px;
`
const TeknisetTiedot = styled.div`
padding-top:20px;
padding-bottom:20px;
display:grid;
grid-template-columns: 1fr;
grid-auto-rows: 26px;
`
const Rivi = styled.div`
display:grid;
grid-template-columns: 1fr 1fr;
padding-left:10px;
padding-right:10px;
background-color: ${props => props.gray ? "#F0F0F0" : "white"};
`
const Selite = styled.span`
font-size: 14px;
line-height: 20px;
`
const Arvo = styled.span`
font-size: 14px;
line-height: 20px;
font-weight: 600;
justify-self: end;
white-space:nowrap;
`
const TuoteGrid = styled.div`
display: grid;
grid-template-columns: ${props => props.desktop === true ? "1fr" : "1fr"};
grid-column-gap: 20px;
`
const Gridi = styled.div`
display: grid;
grid-template-columns: ${props => props.desktop === true ? "1fr 1fr 1fr" : "1fr"};
grid-column-gap: 20px;
`
const ListItem = styled.li`
`

function KuvaKaruselli({ kuvat, desktop }) {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
    };
    return (
        <Slider {...settings}>
            {kuvat.map((kuva, i) => (
                <StaticImage
                    alt="teksti"
                    src={kuva}
                     style={{
                         maxHeight: 100,
                        marginTop: '64px'
                     }}

                />
            ))} 
        </Slider>
    );
}


function Tuote({ tuote, desktop }) {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
    };
    const { tuotteen_nimi, tuotteen_esittelyteksti, tuotteen_lyhyt_esittely, telipaino, rungon_pituus, rungon_korkeus_maasta, rengaskoko, omapaino, lisavarusteet, lavan_pituudet, korkeus_lavan_pohjaan_maasta, kokonaispaino, kippauskulma, kantavuus, aisapaino, tuotteen_kuvat } = tuote;
    return (
        <Fragment>
            
            <Gridi desktop={desktop}>
                <div>
                    <Otsikko>{tuotteen_nimi}</Otsikko>
                    <p>{tuotteen_lyhyt_esittely}</p>
                    <Teksti>{tuotteen_esittelyteksti.map((li, index) => <ListItem key={index}>{li}</ListItem>)}</Teksti>
                    <p style={{paddingBottom: "20px"}}>Videot: <a href="https://youtu.be/pTOGsd-ZTfE" target="_blank">Metaka 180</a>, <a href="https://youtu.be/v2ej9Rny3uw" target="_blank">Metaka 180 ja kaksoisvaihtolava</a></p>
                </div>
                <div>
                    <Otsikko>Tekniset tiedot</Otsikko>
                    <TeknisetTiedot>
                        <Rivi gray>
                            <Selite>Rungon pituus</Selite>
                            <Arvo>{rungon_pituus} m</Arvo>
                        </Rivi>
                        <Rivi>
                            <Selite>Lavan pituudet</Selite>
                            <Arvo>{lavan_pituudet} m</Arvo>
                        </Rivi>
                        <Rivi gray>
                            <Selite>Rungon korkeus maasta</Selite>
                            <Arvo>{rungon_korkeus_maasta} m</Arvo>
                        </Rivi>
                        <Rivi>
                            <Selite>Korkeus lavan pohjaan maasta</Selite>
                            <Arvo>{korkeus_lavan_pohjaan_maasta} m</Arvo>
                        </Rivi>
                        <Rivi gray>
                            <Selite>Kokonaispaino</Selite>
                            <Arvo>{kokonaispaino} kg</Arvo>
                        </Rivi>
                        <Rivi>
                            <Selite>Omapaino</Selite>
                            <Arvo>{omapaino} kg</Arvo>
                        </Rivi>
                        <Rivi gray>
                            <Selite>Telipaino</Selite>
                            <Arvo>{telipaino} kg</Arvo>
                        </Rivi>
                        <Rivi>
                            <Selite>Kantavuus</Selite>
                            <Arvo>{kantavuus} kg</Arvo>
                        </Rivi>
                        <Rivi gray>
                            <Selite>Aisapaino</Selite>
                            <Arvo>{aisapaino} kg</Arvo>
                        </Rivi>
                        <Rivi>
                            <Selite>Kippauskulma</Selite>
                            <Arvo>{kippauskulma} °</Arvo>
                        </Rivi>
                        <Rivi gray>
                            <Selite>Rengaskoko</Selite>
                            <Arvo>{rengaskoko}</Arvo>
                        </Rivi>
                    </TeknisetTiedot>
                </div>
                <div>
                    <Otsikko>Lisävarusteet</Otsikko>
                    <Teksti>{lisavarusteet.map((li, index) => <ListItem key={index}>{li}</ListItem>)}</Teksti>
                </div>
            </Gridi>
        </Fragment>
    )
}

// function Tuote({ tuote, desktop }) {
//     const { tuotteen_nimi, tuotteen_lyhyt_esittely, tuotteen_esittelyteksti, telipaino, rungon_pituus, rungon_korkeus_maasta, rengaskoko, omapaino, lisavarusteet, lavan_pituudet, korkeus_lavan_pohjaan_maasta, kokonaispaino, kippauskulma, kantavuus, aisapaino, tuotteen_kuvat } = tuote;
//     let linktext = tuotteen_nimi.replace(/\s/g, '');
//     let link = `/${linktext.toLowerCase()}`;
//     return (
//         <div>
//             <Otsikko><Link to={link}>{tuotteen_nimi}</Link></Otsikko>
//             {tuotteen_kuvat.length > 0 ? <KuvaKaruselli kuvat={tuotteen_kuvat} desktop /> : <Fragment></Fragment> }
//             <Teksti>{tuotteen_lyhyt_esittely}</Teksti>
//             <Link to={link}>Tuotesivulle</Link>
//         </div>
//     )
// }

export default function TuoteSivuVideoilla({ tuotteet, desktop }) {
    // sort by name
    let sorted = tuotteet.sort((a, b) => a.tuotteen_nimi.toLowerCase().localeCompare(b.tuotteen_nimi.toLowerCase()))
    return (
        <TuoteGrid desktop={desktop}>
            {sorted.map((tuote, index) => (
                <Fragment key={index}>
                    <Tuote tuote={tuote} desktop={desktop} />
                </Fragment>
            ))}
        </TuoteGrid>
    )
}
